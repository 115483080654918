@media only screen and (max-width: 768px) {
  html {
      overflow-x: clip
  }

  .overflow-hidden {
      overflow: hidden
  }
}

.jwc-lportal .mb-30 {
  margin-bottom: 30px
}

.jwc-lportal .input-box .cta-sec:after {
  position: absolute;
  top: 6px;
  left: 100%
}

.jwc-lportal .section_heading--title .different-h2 {
  font-size: 26px;
  line-height: 1.3;
  padding: 15px 0 20px;
  font-weight: 400
}

.jwc-lportal .benefits_wrapper_styled p {
  margin-bottom: 0
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .benefits_wrapper_styled .col-lg-5 {
      order: 2;
      margin-top: 30px
  }

  .jwc-lportal .benefits_wrapper_styled .different-h2 {
      font-size: 18px;
      line-height: 36px
  }

  .jwc-lportal .benefits_wrapper_styled p {
      font-size: 16px
  }
}

.jwc-lportal .our_theatre_slider_each_copy--btns a {
  font-size: 12px
}

.jwc-lportal .our_theatre_slider_each_copy--btns a+a {
  margin-left: 30px
}

@media only screen and (max-width: 1120px) {
  .jwc-lportal .our_theatre_slider_each_copy--btns {
      margin-top: 30px;
      flex-direction: column;
      margin-left: 0;
      justify-content: flex-start
  }

  .jwc-lportal .our_theatre_slider_each_copy--btns a+a {
      margin-top: 25px;
      margin-left: 0
  }

  .jwc-lportal .our_theatre_slider_each_copy--btns a {
      margin-left: 0;
      margin-right: auto
  }
}

.jwc-lportal .community-wrapper .community-grid--each:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 60%;
  background: #000;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8001794468) 0%, rgba(0, 0, 0, 0.3407956933) 48%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  left: 0;
  z-index: 0
}

.jwc-lportal .community-wrapper .content2 {
  z-index: 1
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .community-wrapper .community-grid--each img {
      height: auto
  }
}

.jwc-lportal .highlights_wrapper_container_2 .highlights_wrapper_each_copy--text {
  padding-bottom: 25px
}

.jwc-lportal .highlights_wrapper_container_2 .highlights_wrapper_each_copy--link {
  position: relative;
  top: 0;
  width: auto;
  margin-top: auto
}

.jwc-lportal .highlights_wrapper_container_2 .highlights_wrapper_each_copy {
  background-color: #fff;
  display: flex;
  flex-direction: column
}

.jwc-lportal .highlights_wrapper_container_2 .highlights_wrapper_each_copy--date {
  color: #c63493
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .highlights_wrapper_container_2 .row {
      padding-left: 35px;
      padding-right: 15px;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none
  }

  .jwc-lportal .highlights_wrapper_container_2 .row::-webkit-scrollbar {
      display: none
  }

  .jwc-lportal .highlights_wrapper_container_2 .row .col-lg-4 {
      width: 90%;
      flex: 0 0 90%
  }

  .jwc-lportal .highlights_wrapper_container_2 h4 {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 10px
  }
}

.jwc-lportal .text-c-btn span {
  color: #fff
}

.jwc-lportal .text-c-btn:hover span,
.jwc-lportal .text-c-btn:focus span {
  color: #212529
}

.jwc-lportal .jwc_banner_slider .kma_text_wrapper .d-flex {
  justify-content: center
}

.jwc-lportal .jwc_banner_slider .kma_text_wrapper a+a {
  margin-left: 25px
}

.jwc-lportal .icon-download-icon-arrow:before {
  content: "";
  background-image: url(../../images/download-arrow.svg);
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center
}

.jwc-lportal .whats_on_categories_wrapper .show_name {
  color: #4b4847 !important;
  text-decoration: none !important
}

.jwc-lportal .whats_on_categories_wrapper h4 {
  margin-bottom: 10px
}

.jwc-lportal .whats_on_categories_wrapper p {
  font-size: 16px;
  line-height: 1.2
}

.jwc-lportal .whats_on_categories_wrapper .presenter,
.jwc-lportal .whats_on_categories_wrapper .show_descr {
  font-size: 14px
}

.jwc-lportal .whats_on_categories_wrapper .show_date_bold {
  font-weight: 600
}

.jwc-lportal .whats_on_categories_wrapper .show_price_det {
  display: flex;
  align-items: flex-end;
  margin: 20px 0 0px
}

.jwc-lportal .whats_on_categories_wrapper .show_price_det p {
  margin-bottom: 0;
  margin-right: 10px;
  line-height: 1;
  font-weight: 600
}

.jwc-lportal .whats_on_categories_wrapper .show_price_det span {
  font-size: 12px;
  line-height: 1.15;
  color: #c5299b;
  font-weight: 500
}

.jwc-lportal .whats_on_categories_wrapper .btn_holder .cta-prim {
  margin-right: 15px
}

@media only screen and (max-width: 1050px) {
  .jwc-lportal .whats_on_categories_wrapper .btn_holder .cta-prim+.cta-prim {
      margin-top: 15px;
      min-width: 189px
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .whats_on_categories_wrapper .presenter {
      font-size: 12px
  }

  .jwc-lportal .whats_on_categories_wrapper p {
      font-size: 14px
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .whats_on_categories h2 {
      font-size: 32px;
      max-width: 50%
  }

  .jwc-lportal .whats_on_categories .cta-holder {
      position: absolute !important;
      top: 108px !important;
      right: 0 !important;
      left: auto !important;
      width: -moz-fit-content !important;
      width: fit-content !important
  }

  .jwc-lportal .whats_on_categories .icon-download-icon-arrow {
      white-space: pre-line;
      text-align: center;
      padding-right: 0 !important
  }

  .jwc-lportal .whats_on_categories .icon-download-icon-arrow:before {
      position: relative;
      display: block;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      margin: 0 auto 10px
  }
}

.jwc-lportal .moments .section_heading--text span {
  display: flex;
  justify-content: center;
  align-items: center
}

.jwc-lportal .moments .section_heading--text span:before {
  content: "";
  width: 50px;
  height: 50px;
  background-image: url(../../images/insta.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  margin-right: 15px;
  margin-top: 4px
}

.jwc-lportal .moments .row {
  margin-bottom: 0px
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .moments .container {
      padding-left: 0;
      padding-right: 0
  }

  .jwc-lportal .moments .row {
      padding-left: 25px;
      padding-right: 15px;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none
  }

  .jwc-lportal .moments .row::-webkit-scrollbar {
      display: none
  }

  .jwc-lportal .moments .row .col-lg-4 {
      width: 90%;
      flex: 0 0 90%;
      max-width: 100%;
      margin-bottom: 20px
  }

  .jwc-lportal .moments .section_heading--text span {
      font-size: 22px;
      line-height: 1
  }

  .jwc-lportal .moments .section_heading--text span:before {
      width: 32px;
      height: 32px
  }
}

.jwc-lportal #faq_accordion .btn[aria-expanded=true] span:before {
  color: #004ea8
}

@media only screen and (max-width: 768px) {
  .jwc-lportal #faq_accordion .card-header button[aria-expanded=true] {
      font-weight: 400
  }
}

.jwc-lportal .jwc_banner_wvideo_slider {
  background-color: #f5f2ed
}

.jwc-lportal .jwc_banner_wvideo_slider .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  margin: 0px 5px
}

.jwc-lportal .jwc_banner_wvideo_slider .swiper-pagination-bullet i {
  font-size: 20px;
  line-height: 28px
}

.jwc-lportal .jwc_banner_wvideo_slider .swiper-pagination-bullet-active {
  width: 28px;
  height: 28px
}

@media screen and (max-width: 768px) {
  .jwc-lportal .jwc_banner_wvideo_slider {
      min-height: calc(100vh - 120px)
  }
}

.jwc-lportal .banner_slider_swiper {
  position: relative;
  z-index: 1
}

.jwc-lportal .banner_slider_swiper video {
  width: 100%;
  height: calc(100vh - 80px);
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  max-height: 1300px
}

.jwc-lportal .banner_slider_swiper img {
  width: 100%;
  height: calc(100vh - 80px);
  max-height: 1300px;
  -o-object-fit: cover;
  object-fit: cover;
  display: block
}

.jwc-lportal .banner_slider_swiper .text-container-banner-slider {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  padding: 20px
}

.jwc-lportal .banner_slider_swiper .d-flex {
  justify-content: center;
  margin-top: 25px
}

.jwc-lportal .banner_slider_swiper .d-flex a+a {
  margin-left: 30px
}

.jwc-lportal .banner_slider_swiper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  z-index: 0
}

@media only screen and (max-width: 768px) {

  .jwc-lportal .banner_slider_swiper video,
  .jwc-lportal .banner_slider_swiper img {
      width: 100%;
      height: 100%
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider {
      padding: 50px 40px 50px;
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      color: #4b4847
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider h1 {
      font-size: 35px
  }
}

@media only screen and (max-width: 768px)and (max-width: 500px) {
  .jwc-lportal .banner_slider_swiper .text-container-banner-slider h1 {
      font-size: 33px;
      line-height: 1.3;
      max-width: 240px;
      margin-left: auto;
      margin-right: auto
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .banner_slider_swiper .text-container-banner-slider h1 br {
      display: none
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex {
      flex-direction: column
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex a+a {
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex a.cta-prim {
      border: 1px solid #c92c99
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex a.cta-prim span {
      color: #c92c99
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex a.cta-prim:after {
      background-color: #c92c99
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex a.cta-prim:hover,
  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex a.cta-prim:focus {
      color: #fff
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex a.cta-sec span {
      color: #4b4847
  }

  .jwc-lportal .banner_slider_swiper .text-container-banner-slider .d-flex a.cta-sec:after {
      background-color: #c63493
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .banner_slider_swiper:after {
      display: none
  }
}

.jwc-lportal .mbl_nav_bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 99;
  background-color: #f5f2ed;
  text-align: center
}

@media only screen and (min-width: 768px) {
  .jwc-lportal .mbl_nav_bar {
      display: none
  }
}

.jwc-lportal .mbl_nav_bar a {
  text-decoration: none;
  font-size: 10px;
  color: #4b4847;
  width: 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 13px 10px;
  position: relative
}

.jwc-lportal .mbl_nav_bar a:before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center
}

.jwc-lportal .mbl_nav_bar a.mbl_nav_home:before {
  background-image: url(../../images/home.png)
}

.jwc-lportal .mbl_nav_bar a.mbl_nav_arts:before {
  background-image: url(../../images/arts.png)
}

.jwc-lportal .mbl_nav_bar a.mbl_nav_tickets:before {
  background-image: url(../../images/tickets.png)
}

.jwc-lportal .mbl_nav_bar a.mbl_nav_account:before {
  background-image: url(../../images/account.png)
}

.jwc-lportal .mbl_nav_bar a:not(:last-child):after {
  content: "";
  width: 1px;
  height: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #a67d54
}

.jwc-lportal .mbl_nav_bar a.mbl_active {
  background-color: #a67d54;
  color: #fff
}

.jwc-lportal .mbl_nav_bar a.mbl_active:before {
  filter: brightness(20)
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .jwc_fixed_navbar {
      position: fixed;
      width: 100%
  }

  .jwc-lportal .jwc_footer_bar_inner {
      padding-bottom: 100px !important
  }

  .jwc-lportal .jwc_footer_bar_inner .links {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      text-align: left;
      gap: 5px 20px
  }

  .jwc-lportal .jwc_footer_bar_inner .links li {
      text-align: left
  }

  .jwc-lportal ul.social--UL {
      margin-top: 30px !important;
      justify-content: center;
      gap: 20px
  }

  .jwc-lportal .footer_social_column p {
      display: none
  }

  .jwc-lportal ul.jwc_footer_top_inner_links {
      display: grid;
      gap: 0 20px
  }

  .jwc-lportal ul.jwc_footer_top_inner_links a {
      font-size: 12px !important
  }

  .jwc-lportal ul.jwc_footer_top_inner_links:not(:first-child) {
      grid-template-columns: repeat(2, 1fr);
      border-top: 1px solid #dadada;
      padding-top: 20px
  }

  .jwc-lportal .input-field.grey_color {
      border-color: #c63493
  }

  .jwc-lportal .jwc_navbar_mobile .nav-link .icon-search {
      font-size: 20px
  }

  .jwc-lportal .our_theatre_slider .swiper-slide .our_theatre_slider_each {
      margin-bottom: 0
  }
}

@media only screen and (max-width: 768px)and (max-width: 767px) {
  .jwc-lportal .our_theatre_slider .swiper-slide {
      background-color: #f5f2ed
  }

  .jwc-lportal .our_theatre_slider .swiper-slide .our_theatre_slider_each_copy {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none
  }
}

@media only screen and (max-width: 768px)and (max-width: 767px) {
  .jwc-lportal .our_theatre_slider .our_theatre_slider_each_copy--box {
      height: 100%;
      display: flex;
      flex-direction: column
  }

  .jwc-lportal .our_theatre_slider .our_theatre_slider_each_copy--box .our_theatre_slider_each_copy--btns {
      padding-top: 20px;
      margin-top: auto
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .listFilter.open .filters:after {
      transform: translateY(-50%) rotate(180deg)
  }

  .jwc-lportal .listFilter .filters:after {
      position: absolute;
      top: 50%;
      background: none;
      content: "";
      font-family: "jwc-icon";
      font-size: 8px;
      line-height: 10px;
      color: var(--data-arrow-color);
      transform: translateY(-50%);
      transition: all .2s ease-in-out;
      transform-origin: center;
      right: 20px;
      color: #4a4847
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .swiper_only_dots .swiper-pagination-bullet i {
      display: none
  }

  .jwc-lportal .swiper_only_dots .swiper-pagination-bullet-active {
      width: 13px;
      height: 13px
  }
}

.jwc-lportal .jwc_swiper_fraction_people .swiper-slide {
  transition: .5s ease all
}

.jwc-lportal .faded_slide {
  opacity: .5
}

.jwc-lportal .event_short_details_link {
  margin-top: 0;
  min-width: -moz-fit-content;
  min-width: fit-content
}

.jwc-lportal .event_short_details_link .cta-prim {
  font-size: 12px
}

.jwc-lportal .event_short_details_title,
.jwc-lportal .event_short_details_link {
  float: none
}

.jwc-lportal .event_short_details_content {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.jwc-lportal .show_data {
  color: #4b4847
}

.jwc-lportal .show_data p {
  font-weight: 400
}

.jwc-lportal .show_data p:last-child {
  margin-bottom: 0
}

.jwc-lportal .show_data .show_data_reviews p {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 10px
}

.jwc-lportal .show_data .show_data_reviews p:not(.name) {
  font-weight: 500 !important
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .show_data .show_data_reviews p {
      font-size: 14px
  }
}

.jwc-lportal .show_data .show_data_price.mobile_only {
  margin-top: 20px;
  margin-bottom: 0px
}

.jwc-lportal .show_data .share_box {
  margin-top: 45px
}

.jwc-lportal .show_data.multilineText h2 {
  font-size: 32px;
  line-height: 1.3;
  max-width: 100%;
  margin-bottom: 30px
}

@media only screen and (max-width: 500px) {
  .jwc-lportal .show_data.multilineText h2 {
      margin-bottom: 20px
  }
}

@media only screen and (max-width: 500px) {
  .jwc-lportal .show_data.multilineText.highlightText_sub {
      font-size: 28px;
      line-height: 1.2
  }

  .jwc-lportal .show_data.multilineText.highlightText_sub .share_box {
      display: none
  }
}

@media only screen and (max-width: 500px) {
  .jwc-lportal .show_data.multilineText .buttons .cta-sec {
      display: block;
      width: -moz-fit-content;
      width: fit-content
  }
}

.jwc-lportal .show_data .show_data_additional p {
  margin-bottom: 20px
}

.jwc-lportal .you_may_like_slider .swiper-pagination-fraction {
  display: none
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .you_may_like_slider .you_may_like_buttons_link {
      left: 40%;
      transform: translateX(-40%)
  }
}

.jwc-lportal .kma_with_thumbnails_big_img .swiper-slide {
  z-index: 1
}

.jwc-lportal .kma_with_thumbnails_big_img .swiper-slide .watchVideoClick {
  z-index: 2
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .event_short_details_content {
      flex-direction: column
  }

  .jwc-lportal .event_short_details_content .event_short_details_link {
      width: 100%;
      min-width: -moz-fit-content;
      min-width: fit-content
  }

  .jwc-lportal .event_short_details_content .event_short_details_link a {
      width: 100%;
      margin-right: auto;
      margin-top: 20px
  }

  .jwc-lportal .event_short_details_content .event-buttn-links {
      display: flex;
      -moz-column-gap: 1rem;
      column-gap: 1rem
  }
}

@media only screen and (min-width: 768px)and (max-width: 900px) {

  .jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_date .whats_on_categories_wrapper_date--day,
  .jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_date .jwc-lportal .whats_on_categories_wrapper_date--year {
      font-size: 12px;
      line-height: 18px
  }

  .jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_date .whats_on_categories_wrapper_date--month {
      font-size: 24px;
      line-height: 30px
  }
}

@media only screen and (min-width: 900px)and (max-width: 1200px) {

  .jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_date .whats_on_categories_wrapper_date--day,
  .jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_date .jwc-lportal .whats_on_categories_wrapper_date--year {
      font-size: 14px;
      line-height: 18px
  }

  .jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_date .whats_on_categories_wrapper_date--month {
      font-size: 30px;
      line-height: 30px
  }
}

@media only screen and (min-width: 768px) {
  .jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_row:last-child .row .col-md-6:nth-last-child(2) .whats_on_categories_wrapper_item {
      margin-bottom: 0
  }
}

.jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_row:last-child .row .col-md-6:last-child .whats_on_categories_wrapper_item {
  margin-bottom: 0
}

.jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_item_copy .show_date_bold {
  margin-bottom: 5px
}

.jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_item_copy .features-icon {
  margin-top: 0;
  margin-bottom: 40px
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .whats_on_categories_wrapper_result .whats_on_categories_wrapper_item_copy .features-icon {
      margin-bottom: 25px
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .screen_height_Three_Quarters p {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto
  }

  .jwc-lportal .screen_height_Three_Quarters p br {
      display: none
  }
}

.jwc-lportal .art-wrapper .art-list--each:last-child {
  margin-bottom: 160px
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .art-wrapper .art-list--each:last-child {
      margin-bottom: 65px
  }
}

.jwc-lportal .jwc_image_slider .jwc_image_slider_each img {
  width: 100%
}

.jwc-lportal .jwc_contactus .col-lg-8.col-md-7 .col-lg-11 {
  padding: 0
}

@media only screen and (max-width: 500px) {
  .jwc-lportal .jwc_contactus .col-lg-8.col-md-7 .container {
      padding: 0
  }

  .jwc-lportal .jwc_contactus .col-lg-8.col-md-7 .container .cus_accordion .card-header {
      padding: 0 15px
  }

  .jwc-lportal .jwc_contactus .col-lg-8.col-md-7 .container .cus_accordion .card-header button .icon-arrow-down {
      right: 15px
  }
}

@media only screen and (max-width: 500px) {
  .jwc-lportal .jwc_contactus .contactus_sidebox {
      padding: 30px
  }
}

.jwc-lportal .jwc_contactus .social--UL a:hover:before,
.jwc-lportal .jwc_contactus .social--UL a:focus:before {
  color: #a67d54 !important
}

.jwc-lportal .contactus_form .section_heading {
  padding-bottom: 0;
  max-width: 83%
}

.jwc-lportal .contactus_form .jwc_form {
  margin-top: 20px
}

.jwc-lportal .contactus_form .jwc_form .form-group {
  margin-bottom: 30px
}

.jwc-lportal .contactus_form .jwc_form .form-group .col-3.pr-0+.col-9.pl-0.input-box input {
  border-left: 0 !important
}

@media only screen and (min-width: 767px)and (max-width: 1000px) {
  .jwc-lportal .contactus_form .jwc_form .form-group .col-3.pr-0 {
      min-width: 110px
  }

  .jwc-lportal .contactus_form .jwc_form .form-group .col-9.pl-0.input-box {
      max-width: calc(100% - 110px)
  }
}

@media only screen and (max-width: 500px) {
  .jwc-lportal .contactus_form .jwc_form .form-group {
      padding: 0 0 0 0
  }
}

.jwc-lportal .contactus_form .jwc_form .form-group .input-field,
.jwc-lportal .contactus_form .jwc_form .form-group .dropdown--select {
  height: 60px;
  line-height: 60px
}

.jwc-lportal .contactus_form .jwc_form .form-group .input-field.textarea,
.jwc-lportal .contactus_form .jwc_form .form-group .dropdown--select.textarea {
  height: 180px;
  line-height: 120%
}

@media only screen and (max-width: 500px) {

  .jwc-lportal .contactus_form .jwc_form .form-group .input-field.textarea,
  .jwc-lportal .contactus_form .jwc_form .form-group .dropdown--select.textarea {
      height: 100px
  }
}

@media only screen and (max-width: 500px) {

  .jwc-lportal .contactus_form .jwc_form .form-group .input-field,
  .jwc-lportal .contactus_form .jwc_form .form-group .dropdown--select {
      height: 45px;
      line-height: 45px
  }

  .jwc-lportal .contactus_form .jwc_form .form-group .input-field:after,
  .jwc-lportal .contactus_form .jwc_form .form-group .dropdown--select:after {
      right: 15px
  }
}

@media only screen and (min-width: 767px)and (max-width: 1000px) {

  .jwc-lportal .contactus_form .jwc_form .form-group .input-field.r-10,
  .jwc-lportal .contactus_form .jwc_form .form-group .dropdown--select.r-10 {
      min-width: 110px
  }
}

.jwc-lportal .contactus_form .jwc_form .form-group .input-field.r-10:after,
.jwc-lportal .contactus_form .jwc_form .form-group .dropdown--select.r-10:after {
  right: 10px
}

@media only screen and (max-width: 500px) {

  .jwc-lportal .contactus_form .jwc_form .form-group .input-field.r-10:after,
  .jwc-lportal .contactus_form .jwc_form .form-group .dropdown--select.r-10:after {
      right: 15px
  }
}

.jwc-lportal .contactus_form .jwc_form .form-group .checkbox {
  margin-bottom: 10px
}

.jwc-lportal .contactus_form .jwc_form .form-group .checkbox label {
  color: #c63493;
  text-decoration: underline
}

.jwc-lportal .contactus_form .jwc_form .form-group .checkbox label:before {
  border: 1px solid #707070;
  left: -35px
}

.jwc-lportal .contactus_form .jwc_form .form-group .checkbox label:after {
  left: -34px
}

.jwc-lportal .screen_height_Three_Quarters .kma_picture_wrapper {
  height: 100% !important;
  -o-object-position: center;
  object-position: center
}

.jwc-lportal .screen_height_Three_Quarters .kma_picture_wrapper .kma_picture_wrapper--img {
  height: 100%;
  display: block;
  margin-top: 0
}

.jwc-lportal .screen_height_Three_Quarters .kma_picture_wrapper .kma_picture_wrapper {
  height: 100% !important
}

.jwc-lportal .image_text_centered_cont {
  background-color: var(--data-bgColor)
}

.jwc-lportal .benefits_wrapper2 .bg_before_left_none {
  padding: 0 0 0 0
}

.jwc-lportal .benefits_wrapper2 .bg_before_left_none:before {
  display: none
}

.jwc-lportal .benefits_wrapper2 .bg_before_left_none p {
  padding-top: 30px
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .benefits_wrapper2 .bg_before_left_none p {
      margin-bottom: 0
  }
}

.jwc-lportal .blog_template_inner {
  background-color: var(--blog_template_top);
  padding-top: 120px;
  padding-bottom: 120px
}

.jwc-lportal .blog_template_inner .blog_share_cont {
  margin-bottom: 40px
}

.jwc-lportal .blog_template_inner .blog_share_cont .content2 p {
  margin-bottom: 10px
}

.jwc-lportal .blog_template_inner .blog_share_cont+.blog_content_cont {
  padding-top: 0
}

.jwc-lportal .blog_template_inner .blog_content_cont {
  padding-top: 120px;
  padding-bottom: 120px
}

.jwc-lportal .blog_template_inner .blog_content_cont .content2 p {
  margin-bottom: 30px
}

.jwc-lportal .blog_template_inner .blog_content_cont .content2 p:last-child {
  margin-bottom: 0px
}

.jwc-lportal .blog_template_inner .pink_color p {
  color: #c5299b
}

.jwc-lportal .blog_template_inner .content3 {
  margin-bottom: 10px
}

.jwc-lportal .blog_template_inner .quoted-content {
  background-color: var(--data-highlightbgColor);
  padding: 100px 0
}

.jwc-lportal .blog_template_inner .quoted-content h4 {
  font-weight: 400;
  font-style: italic;
  margin-bottom: 20px;
  letter-spacing: .5px;
  line-height: 1.5
}

.jwc-lportal .blog_template_inner .quoted-content p {
  font-size: 16px;
  font-weight: 600
}

.jwc-lportal .blog_template_inner .highlighted-content {
  padding-top: 120px
}

.jwc-lportal .blog_template_inner .highlighted-content h5 {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600
}

.jwc-lportal .blog_template_inner .highlighted-content h5:before {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  width: 6px;
  height: 100%;
  background: var(--data-headingLineColor)
}

.jwc-lportal .blog_template_inner .highlighted-content .closing_tags {
  margin-top: 50px;
  padding-top: 30px;
  border-top: 1px solid #707070;
  display: flex;
  align-items: center
}

.jwc-lportal .blog_template_inner .highlighted-content .closing_tags .head_closing_tag {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 0 !important;
  margin-right: 15px
}

@media only screen and (max-width: 767px) {
  .jwc-lportal .blog_template_inner {
      padding-top: 65px;
      padding-bottom: 65px
  }

  .jwc-lportal .blog_template_inner .blog_share_cont {
      margin-bottom: 30px
  }

  .jwc-lportal .blog_template_inner .blog_share_cont .share_box {
      margin-top: 20px
  }

  .jwc-lportal .blog_template_inner .blog_content_cont {
      padding-top: 65px;
      padding-bottom: 65px
  }

  .jwc-lportal .blog_template_inner .quoted-content {
      padding-top: 50px;
      padding-bottom: 50px
  }

  .jwc-lportal .blog_template_inner .quoted-content h4 {
      max-width: 90%
  }

  .jwc-lportal .blog_template_inner .highlighted-content {
      padding-top: 65px
  }

  .jwc-lportal .blog_template_inner .highlighted-content h5 {
      font-size: 15px
  }

  .jwc-lportal .blog_template_inner .highlighted-content .closing_tags {
      flex-wrap: wrap
  }

  .jwc-lportal .blog_template_inner .highlighted-content .closing_tags .head_closing_tag {
      width: 100%;
      margin-bottom: 5px !important
  }

  .jwc-lportal .blog_template_inner .highlighted-content .share_box {
      margin-top: 20px
  }
}

.jwc-lportal .pink-text {
  color: #c63493
}

.jwc-lportal .sitemap_holder ul {
  padding-left: 20px
}

.jwc-lportal .sitemap_holder .content2 li {
  font-size: 18px;
  line-height: 30px
}

.jwc-lportal .sitemap_holder .content2 li a {
  text-decoration: none
}

.jwc-lportal .sitemap_holder .content2 li a:hover {
  text-decoration: underline
}

@media only screen and (max-width: 1370px) {
  .jwc-lportal .sitemap_holder .content2 li {
      font-size: 16px;
      line-height: 28px
  }
}

@media only screen and (max-width: 991px) {
  .jwc-lportal .sitemap_holder .content2 li {
      font-size: 14px;
      line-height: 22px
  }
}

.jwc-lportal .sitemap_holder h4 {
  position: relative;
  padding: 10px 0
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .sitemap_holder h4 {
      width: 100%
  }

  .jwc-lportal .sitemap_holder h4:after {
      content: "";
      font-family: "jwc-icon" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      font-size: 8px;
      transition: .5s ease all;
      color: #c5299b
  }

  .jwc-lportal .sitemap_holder h4.active {
      font-weight: 600
  }

  .jwc-lportal .sitemap_holder h4.active:after {
      transform: translateY(-50%) rotate(0deg)
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .sitemap_holder .collapsible-list {
      height: 0;
      overflow: hidden;
      transition: .5s ease all
  }

  .jwc-lportal .sitemap_holder .collapsible-list ul {
      padding-bottom: 20px
  }
}

.jwc-lportal .sitemap_holder .row+.row {
  position: relative;
  margin-top: 50px;
  padding-top: 60px
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .sitemap_holder .row+.row {
      margin-top: 0px;
      padding-top: 0px
  }
}

.jwc-lportal .sitemap_holder .row+.row:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 100px);
  height: 1px;
  background-color: #707070
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .sitemap_holder .row+.row:after {
      display: none
  }
}

.jwc-lportal .smaller_p {
  font-size: 16px
}

.jwc-lportal .artwork_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 50px
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .artwork_grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px
  }
}

.jwc-lportal .artwork_grid img {
  width: 100%;
  margin-bottom: 15px
}

.jwc-lportal .artwork_grid .artwork_grid_col {
  color: inherit;
  text-decoration: none
}

.jwc-lportal .artwork_grid .artwork_grid_col:last-child p {
  margin-bottom: 0
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .legal_tabs_container .container .row {
      margin: 0 -20px
  }

  .jwc-lportal .legal_tabs_container .container .row .col-sm-8,
  .jwc-lportal .legal_tabs_container .container .row .col-sm-4 {
      padding: 0
  }
}

.jwc-lportal .legal_tabs_container .legal-list-toggle {
  font-size: 12px;
  line-height: 1.4;
  padding: 10px 35px 10px 20px;
  background-color: #f5f2ed;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  cursor: pointer
}

.jwc-lportal .legal_tabs_container .legal-list-toggle.active {
  background-color: #c92c99
}

.jwc-lportal .legal_tabs_container .legal-list-toggle+h4 {
  margin-top: 4px
}

.jwc-lportal .legal_tabs_container .legal-list-toggle:after {
  content: "";
  font-family: "jwc-icon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg) scale(0);
  font-size: 8px;
  transition: .5s ease all;
  color: #fff
}

.jwc-lportal .legal_tabs_container .legal-list-toggle.active {
  color: #fff
}

.jwc-lportal .legal_tabs_container .legal-list-toggle.active:after {
  transform: translateY(-50%) rotate(-90deg) scale(1)
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .legal_tabs_container .legal-list-toggle {
      background-color: rgba(0, 0, 0, 0);
      margin-bottom: 0
  }

  .jwc-lportal .legal_tabs_container .legal-list-toggle:after {
      color: #4b4847;
      transform: translateY(-50%) rotate(180deg)
  }

  .jwc-lportal .legal_tabs_container .legal-list-toggle.active {
      background-color: rgba(0, 0, 0, 0);
      color: #4b4847
  }

  .jwc-lportal .legal_tabs_container .legal-list-toggle.active:after {
      transform: translateY(-50%) rotate(0deg)
  }
}

@media only screen and (min-width: 768px) {
  .jwc-lportal .legal_tabs_container .legal-toggle-content h4 {
      display: none
  }
}

.jwc-lportal .legal_tabs_container .legal-toggle-content .legal-content {
  height: 0;
  overflow: hidden;
  transition: .5s ease all
}

.jwc-lportal .legal_tabs_container .legal-toggle-content .legal-content h5 {
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px
}

.jwc-lportal .legal_tabs_container .legal-toggle-content .legal-content ul {
  padding-left: 10px;
  font-weight: 300
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .legal_tabs_container .legal-toggle-content .legal-content {
      padding-left: 20px;
      padding-right: 20px
  }

  .jwc-lportal .legal_tabs_container .legal-toggle-content .legal-content.active {
      border-top: 1px solid #dbdbdb
  }

  .jwc-lportal .legal_tabs_container .legal-toggle-content .legal-content .content2 {
      padding-top: 20px;
      padding-bottom: 30px
  }

  .jwc-lportal .legal_tabs_container .legal-toggle-content:first-child {
      border-top: 1px solid #dbdbdb
  }

  .jwc-lportal .legal_tabs_container .legal-toggle-content:last-child .legal-list-toggle {
      border-bottom: 1px solid #dbdbdb
  }

  .jwc-lportal .legal_tabs_container .legal-toggle-content+.legal-toggle-content {
      border-top: 1px solid #dbdbdb
  }
}

.jwc-lportal .split_column_theatre_slider .our_theatre_slider_each {
  display: grid;
  grid-template-columns: repeat(2, 1fr)
}

.jwc-lportal .split_column_theatre_slider .our_theatre_slider_each .our_theatre_slider_each--img {
  width: 100% !important;
  height: 100%
}

.jwc-lportal .split_column_theatre_slider .our_theatre_slider_each .our_theatre_slider_each--img img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.jwc-lportal .split_column_theatre_slider .our_theatre_slider_each .our_theatre_slider_each_copy {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, .0588235294)
}

.jwc-lportal .split_column_theatre_slider .our_theatre_slider_each .our_theatre_slider_each_copy .our_theatre_slider_each_copy--box.content3 {
  transform: none !important
}

@media only screen and (max-width: 767px) {
  .jwc-lportal .split_column_theatre_slider .our_theatre_slider_each {
      grid-template-columns: auto
  }
}

@media only screen and (min-width: 990px)and (max-width: 1400px) {
  .jwc-lportal .split_column_theatre_slider .our_theatre_slider_each .our_theatre_slider_each_copy--btns {
      position: relative;
      flex-wrap: wrap;
      justify-content: flex-start
  }

  .jwc-lportal .split_column_theatre_slider .our_theatre_slider_each .our_theatre_slider_each_copy--btns .cta-sec.color-pink {
      margin-right: 40px
  }
}

@media only screen and (min-width: 990px)and (max-width: 1400px)and (min-width: 990px)and (max-width: 1350px) {
  .jwc-lportal .split_column_theatre_slider .our_theatre_slider_each .our_theatre_slider_each_copy--btns .cta-sec.color-pink {
      margin-right: 100px;
      margin-top: 20px;
      margin-left: 0
  }
}

@media only screen and (min-width: 990px)and (max-width: 1400px)and (min-width: 990px)and (max-width: 1120px) {
  .jwc-lportal .split_column_theatre_slider .our_theatre_slider_each .our_theatre_slider_each_copy--btns .cta-sec.color-pink {
      margin-right: auto
  }
}

.jwc-lportal .split_column_theatre_slider .swiper-button-next,
.jwc-lportal .split_column_theatre_slider .swiper-button-prev {
  top: 40%
}

@media only screen and (min-width: 990px)and (max-width: 1024px) {
  .jwc-lportal .split_column_theatre_slider {
      padding-left: 20px
  }

  .jwc-lportal .split_column_theatre_slider .swiper-arrows {
      display: none
  }
}

.jwc-lportal .additional-details-inner {
  margin-top: 60px
}

.jwc-lportal .additional-details-inner .additional-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 70px
}

@media only screen and (max-width: 767px) {
  .jwc-lportal .additional-details-inner .additional-details-grid {
      margin-top: 30px;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .jwc_banner_slider_blog {
      min-height: auto
  }
}

.jwc-lportal .jwc_banner_slider_blog .blog_cat {
  text-transform: uppercase;
  color: var(--data-event-categoryColor)
}

.jwc-lportal .jwc_banner_slider_blog .text-left {
  text-align: left
}

.jwc-lportal .jwc_banner_slider_blog .text-left .d-flex.align-items-center {
  justify-content: flex-start
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .jwc_banner_slider_blog .text-left .d-flex.align-items-center {
      align-items: flex-start !important
  }

  .jwc-lportal .jwc_banner_slider_blog .text-left .d-flex.align-items-center .cta-sec {
      margin-left: 0
  }

  .jwc-lportal .jwc_banner_slider_blog .text-left .d-flex.align-items-center .cta-sec span {
      color: var(--data-event-mobileColor)
  }

  .jwc-lportal .jwc_banner_slider_blog .text-left .d-flex.align-items-center .cta-sec::after {
      background-color: var(--data-event-mobileColor)
  }
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .jwc_banner_slider_blog .text-container-banner-slider {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
      color: var(--data-event-mobileColor)
  }

  .jwc-lportal .jwc_banner_slider_blog .text-container-banner-slider h1 {
      max-width: 100%
  }

  .jwc-lportal .jwc_banner_slider_blog .text-container-banner-slider .d-flex a.cta-prim {
      border-color: var(--data-event-mobileColor)
  }

  .jwc-lportal .jwc_banner_slider_blog .text-container-banner-slider .d-flex a.cta-prim span {
      color: var(--data-event-mobileColor)
  }

  .jwc-lportal .jwc_banner_slider_blog .text-container-banner-slider .d-flex a.cta-sec span {
      color: var(--data-event-mobileColor)
  }

  .jwc-lportal .jwc_banner_slider_blog .text-container-banner-slider .d-flex a.cta-sec:after {
      background-color: var(--data-event-mobileColor)
  }

  .jwc-lportal .jwc_banner_slider_blog .swiper-pagination {
      display: none
  }
}

.jwc-lportal section {
  background-color: var(--data-backgroundColor)
}

.jwc-lportal .browse_by_category {
  overflow: hidden
}

@media only screen and (max-width: 1000px) {
  .jwc-lportal .browse_by_category .jwc_swiper_normal_p20 {
      padding-left: 0 !important
  }
}

.jwc-lportal .browse_by_category .swiper-container {
  overflow: visible
}

.jwc-lportal .browse_by_category .swiper-arrows {
  width: 120px;
  margin: 30px 0 20px;
  position: absolute;
  top: -80px;
  right: 0
}

.jwc-lportal .browse_by_category .swiper-arrows .swiper-button-next {
  height: 12px;
  width: 40px;
  outline: none
}

.jwc-lportal .browse_by_category .swiper-arrows .swiper-button-next:after {
  content: "";
  font-family: "jwc-icon" !important;
  font-size: 13px;
  color: #fff;
  color: var(--data-arrowColor);
  opacity: .5;
  transition: .25s ease-in-out
}

.jwc-lportal .browse_by_category .swiper-arrows .swiper-button-next:hover:after {
  opacity: 1
}

.jwc-lportal .browse_by_category .swiper-arrows .swiper-button-next:focus:after {
  opacity: 1;
  outline: solid 1px #a67d54
}

.jwc-lportal .browse_by_category .swiper-arrows .swiper-button-prev {
  height: 12px;
  width: 40px;
  outline: none
}

.jwc-lportal .browse_by_category .swiper-arrows .swiper-button-prev:after {
  content: "";
  font-family: "jwc-icon" !important;
  font-size: 13px;
  color: #fff;
  color: var(--data-arrowColor);
  opacity: .5;
  transition: .25s ease-in-out
}

.jwc-lportal .browse_by_category .swiper-arrows .swiper-button-prev:hover:after {
  opacity: 1
}

.jwc-lportal .browse_by_category .swiper-arrows .swiper-button-prev:focus:after {
  opacity: 1;
  outline: solid 1px #a67d54
}

@media all and (max-width: 500px) {
  .jwc-lportal .browse_by_category .swiper-arrows {
      display: none
  }
}

.jwc-lportal .browse_by_category button.cat_title {
  padding: 0;
  position: relative;
  border: 0;
  outline: 0;
  width: 100%;
  cursor: pointer
}

.jwc-lportal .browse_by_category button.cat_title img {
  width: 100%;
  height: 100%
}

.jwc-lportal .browse_by_category button.cat_title h3 {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  z-index: 1;
  width: 100%;
  font-size: 32px
}

.jwc-lportal .browse_by_category button.cat_title.active h3 {
  color: #c63493
}

.jwc-lportal .browse_by_category button.cat_title.active:after {
  background: rgba(21, 21, 21, .86)
}

.jwc-lportal .browse_by_category button.cat_title:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(21, 21, 21, .4);
  top: 0;
  left: 0;
  z-index: 0
}

.jwc-lportal .blog_listing_results .highlights_wrapper_each {
  height: auto;
  margin-bottom: 50px;
  box-shadow: 0px 8px 9px rgba(163, 163, 163, .22)
}

.jwc-lportal .blog_listing_results .highlights_wrapper_each .highlights_wrapper_each_copy {
  background-color: #fff
}

.jwc-lportal .blog_listing_results .highlights_wrapper_each .highlights_wrapper_each_copy--cat {
  color: #c63493;
  font-weight: 500;
  letter-spacing: 2px
}

.jwc-lportal .blog_listing_results .highlights_wrapper_each .highlights_wrapper_each_copy--date {
  color: inherit;
  text-shadow: none;
  margin-bottom: 20px
}

.jwc-lportal .blog_listing_results .highlights_wrapper_each .highlights_wrapper_each_copy--text {
  padding-bottom: 30px
}

.jwc-lportal .blog_listing_results .highlights_wrapper_each .highlights_wrapper_each_copy--link {
  position: relative;
  bottom: auto
}

.jwc-lportal .customer_care_form .section_heading {
  max-width: 100%
}

.jwc-lportal .customer_care_form .contactus_sidebox a {
  color: var(--data-techspecs-textColor);
  font-weight: 300
}

.jwc-lportal .customer_care_form .contactus_sidebox .cta-sec {
  font-weight: 600;
  font-size: 12px
}

.jwc-lportal .cta-icon {
  display: flex;
  align-items: center;
  color: var(--data-techspecs-textColor);
  font-weight: 300
}

.jwc-lportal .cta-icon img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  -o-object-fit: contain;
  object-fit: contain
}

@media only screen and (max-width: 500px) {
  .jwc-lportal .cta-icon img {
      width: 20px;
      height: 20px
  }
}

.jwc-lportal .cta-icon:not(:last-child) {
  margin-bottom: 20px
}

.jwc-lportal .common_sec .content2 {
  max-width: 100%
}

.jwc-lportal .vip_highlight_slider .swiper-container {
  overflow: visible
}

.jwc-lportal .vip_highlight_slider .swiper-container .swiper-arrows {
  top: -53px
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .vip_box_wrapper .swiper-slide {
      background-color: #fff
  }
}

.jwc-lportal .plan-your-next-big-event button.btn.btn-link {
  position: relative
}

.jwc-lportal .plan-your-next-big-event button.btn.btn-link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  background: #c63493;
  top: -2px;
  left: -1px;
  transition: .5s ease all
}

.jwc-lportal .plan-your-next-big-event button.btn.btn-link[aria-expanded=true]:after {
  width: 60%
}

@media(max-width: 991px) {
  .jwc-lportal .plan-your-next-big-event .accordian_with_slider .card-slider {
      margin: 0
  }
}

@media only screen and (min-width: 992px) {
  .jwc-lportal .feature-shows-wrapper-cust .feature_content_sm {
      padding-top: 40px
  }
}

.jwc-lportal .feature-shows-wrapper-cust .feature_content_sm .cta-prim {
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-left: 20px
}

.jwc-lportal .feature-shows-wrapper-cust .feature_content_sm h4 {
  font-weight: 400
}

.jwc-lportal .feature-shows-wrapper-cust .feature_content_sm .date-time-container {
  padding-right: 20px !important
}

.jwc-lportal .feature-shows-wrapper-cust .feature_content_sm .date-time-container .features-icon {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap
}

@media only screen and (min-width: 1200px) {
  .jwc-lportal .feature-shows-wrapper-cust .feature_content_sm .date-time-container .features-icon {
      gap: 30px
  }
}

.jwc-lportal .feature-shows-wrapper-cust .feature_content_sm .date-time-container .features-icon .label {
  font-weight: 300
}

@media only screen and (min-width: 992px) {
  .jwc-lportal .feature-shows-wrapper-cust .feature_shows_slider_each--img {
      max-width: calc(100% - 60px);
      margin: 0 auto -40px !important;
      display: block
  }
}

.jwc-lportal .feature-shows-wrapper-cust .favt-icon {
  z-index: 2
}

.jwc-lportal .feature-shows-wrapper-cust .genre-icon {
  font-size: 12px;
  background: rgba(255, 255, 255, .8);
  position: absolute;
  left: 15px;
  top: 15px;
  padding: 2px 10px;
  text-transform: uppercase;
  font-weight: 500;
  z-index: 2
}

.jwc-lportal .feature-shows-wrapper-cust .genre-icon a,
.jwc-lportal .feature-shows-wrapper-cust .genre-icon a:hover,
.jwc-lportal .feature-shows-wrapper-cust .genre-icon a:focus {
  color: #0c0c0c
}

.jwc-lportal .wishlist_wrapper_list {
  border: 0;
  background-color: rgba(0, 0, 0, 0)
}

.jwc-lportal .wishlist_wrapper_list .wishlist_wrapper_list_each {
  border-top: rgba(112, 112, 112, .35) 1px solid;
  border-bottom: rgba(112, 112, 112, .35) 1px solid
}

@media only screen and (max-width: 991px) {
  .jwc-lportal .wishlist_wrapper_list {
      height: 62px !important
  }

  .jwc-lportal .wishlist_wrapper_list .filters {
      border: rgba(112, 112, 112, .35) 1px solid;
      border-bottom: 1px solid rgba(112, 112, 112, .35) !important;
      max-width: calc(100% - 50px);
      margin: 0 auto;
      background-color: #fff
  }
}

.jwc-lportal .wishlist_jwc_slider {
  position: relative
}

.jwc-lportal .wishlist_jwc_slider .highlights_wrapper_each {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, .05);
  height: auto
}

.jwc-lportal .wishlist_jwc_slider .highlights_wrapper_each--img {
  position: relative
}

.jwc-lportal .wishlist_jwc_slider .floating_wrapper {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.jwc-lportal .wishlist_jwc_slider .floating_wrapper div {
  display: flex;
  align-items: center;
  gap: 15px
}

.jwc-lportal .wishlist_jwc_slider .floating_wrapper span {
  color: #000;
  background-color: #fff;
  font-weight: 600;
  padding: 10px 14px;
  font-size: 14px
}

.jwc-lportal .wishlist_jwc_slider .floating_wrapper .wishlist_icon {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer
}

.jwc-lportal .wishlist_jwc_slider .floating_wrapper .wishlist_icon img {
  transform: none !important;
  display: block
}

@media only screen and (min-width: 650px)and (max-width: 900px) {
  .jwc-lportal .wishlist_jwc_slider .floating_wrapper span {
      font-size: 8px;
      padding: 5px 7px
  }

  .jwc-lportal .wishlist_jwc_slider .floating_wrapper .wishlist_icon {
      display: block;
      width: 10px;
      height: 10px
  }
}

.jwc-lportal .wishlist_jwc_slider .highlights_wrapper_each_copy {
  background-color: #fff
}

.jwc-lportal .wishlist_jwc_slider .highlights_wrapper_each_copy .highlights_wrapper_each_copy--text {
  padding-bottom: 25px
}

.jwc-lportal .wishlist_jwc_slider .highlights_wrapper_each_copy .highlights_wrapper_each_copy--link {
  position: relative;
  bottom: auto
}

@media screen and (max-width: 768px) {
  .jwc-lportal .user_profile_section {
      padding-left: 0;
      padding-right: 0
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container {
      padding: 0
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-tab-container {
      padding: 0rem
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-tab-container .user_tabs li {
      padding-left: 1rem;
      font-size: 18px
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-tab-container .user_tabs li:first-child,
  .jwc-lportal .user_profile_section .user_profile_mian_container .user-tab-container .user_tabs li:nth-child(2),
  .jwc-lportal .user_profile_section .user_profile_mian_container .user-tab-container .user_tabs li:nth-child(3) {
      padding-right: 1rem
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-profile-container .sub_tab_container2 .activity_content {
      -moz-column-gap: 1rem;
      column-gap: 1rem;
      align-items: flex-start
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-profile-container .sub_tab_container2 .activity_content .text-content .main-title {
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-profile-container .sub_tab_container2 .activity_content .text-content .address {
      flex-direction: column
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-profile-container .sub_tab_container2 .activity_content .text-content .address .theater-name strong {
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-profile-container .sub_tab_container2 .activity_content .text-content .address .tickets {
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-profile-container .sub_tab_container2 .activity_content .text-content .address .tickets strong {
      font-size: 14px;
      line-height: 24px
  }
}

@media screen and (max-width: 600px) {
  .jwc-lportal .user_profile_section .user_profile_mian_container {
      padding: 0
  }

  .jwc-lportal .user_profile_section .user_profile_mian_container .user-tab-container {
      background-color: #fff;
      padding: 0rem .5rem
  }
}

.jwc-lportal .user-profile-container {
  box-shadow: 0px 0px 3px 1px rgba(136, 136, 136, .4196078431)
}

.jwc-lportal .user-profile-container .user-name {
  background-color: #fff;
  padding: 1rem 1.5rem
}

.jwc-lportal .user-profile-container .user-name p {
  font-size: 50px;
  line-height: 60px;
  color: #4b4847;
  font-weight: 500;
  font-family: "Montserrat";
  margin-bottom: 0rem
}

.jwc-lportal .user-profile-container .user-name h3 {
  font-size: 50px;
  line-height: 60px;
  color: #4b4847;
  font-weight: 500;
  font-family: "Montserrat"
}

.jwc-lportal .user-profile-container .user-tab-container .user_tab_list_center {
  display: flex;
  justify-content: center;
  background-color: #f5f2ed
}

.jwc-lportal .user-profile-container .user-tab-container .user_tabs {
  list-style: none;
  display: flex;
  padding: 1.5rem;
  margin-bottom: 0rem
}

.jwc-lportal .user-profile-container .user-tab-container .user_tabs li {
  padding-left: 2rem;
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  color: #a67d54
}

.jwc-lportal .user-profile-container .user-tab-container .user_tabs li:first-child,
.jwc-lportal .user-profile-container .user-tab-container .user_tabs li:nth-child(2) {
  border-right: 1px solid;
  padding-right: 2rem;
  border-right-color: #a67d54
}

.jwc-lportal .user-profile-container .user-tab-container .user_tabs .active-tab {
  color: #c92c99
}

.jwc-lportal .user-profile-container .sub_tab_container {
  background-color: #fff
}

.jwc-lportal .user-profile-container .sub_tab_container .child-sub-tab-container {
  padding: 0
}

.jwc-lportal .user-profile-container .sub_tab_container .nav-tabs {
  display: none
}

@media screen and (min-width: 768px) {
  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs {
      display: flex;
      border-bottom: 0;
      cursor: pointer
  }

  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item {
      margin-bottom: 0px;
      border: 1px solid #dedede;
      max-width: 20%;
      width: 100%
  }

  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item:nth-child(1),
  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item:nth-child(2),
  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item:nth-child(3),
  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item:nth-child(4),
  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item:nth-child(5) {
      border-left: 0
  }

  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item:nth-child(5) {
      border-right: 0
  }

  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item .nav-link {
      text-align: center;
      padding: 1rem 1.5rem;
      color: #a67d54;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 700;
      font-family: "Montserrat"
  }

  .jwc-lportal .user-profile-container .sub_tab_container .nav-tabs .nav-item .nav-link.active {
      color: #c92c99;
      border-color: #fff
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .card {
      border: none
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .card .card-header {
      display: none !important
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .card .collapse {
      display: block
  }
}

@media screen and (max-width: 767px) {
  .jwc-lportal .user-profile-container .sub_tab_container .tab-content {
      padding: 1rem
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .tab-pane {
      display: block;
      opacity: 1
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .tab-pane.card {
      margin-bottom: 1rem
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .tab-pane.card .card-header {
      background-color: #fff;
      padding: 1rem;
      position: relative
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .tab-pane.card .card-header h5 a {
      color: #000;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      text-transform: uppercase
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .tab-pane.card .card-header h5 a:hover {
      text-decoration: none
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .tab-pane.card .card-header h5 {
      position: relative
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .tab-pane.card .card-header h5 .accordion-toggle:after {
      content: "";
      font-family: "jwc-icon";
      font-size: 8px;
      line-height: 10px;
      color: #4a4847;
      transform: translateY(-50%) rotate(180deg);
      float: right;
      position: absolute;
      right: 0;
      top: 15px
  }

  .jwc-lportal .user-profile-container .sub_tab_container .tab-content .tab-pane.card .card-header h5 .accordion-toggle.collapsed:after {
      content: "";
      -webkit-transform: translateY(-50%) rotate(0deg);
      position: absolute;
      right: 0;
      top: 15px
  }
}

.jwc-lportal .user-profile-container .sub_tab_container.sub_tab_block {
  display: none
}

.jwc-lportal .user-profile-container .sub_tab_container.active-tab {
  display: inherit
}

.jwc-lportal .user-profile-container .sub_tab_container2 {
  background: #fff;
  padding: 1rem 1.5rem
}

.jwc-lportal .user-profile-container .sub_tab_container2 .activity_content {
  display: flex;
  align-items: center;
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
  border-bottom: 1px solid rgba(112, 112, 112, .3019607843);
  padding: 2rem 0rem 1.2rem
}

.jwc-lportal .user-profile-container .sub_tab_container2 .activity_content.activity_content:nth-child(5) {
  padding-top: 1.5rem;
  padding-bottom: .5rem
}

.jwc-lportal .user-profile-container .sub_tab_container2 .activity_content .text-content .main-title {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847;
  margin-bottom: 5px
}

.jwc-lportal .user-profile-container .sub_tab_container2 .activity_content .text-content .address {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 1.7rem;
  column-gap: 1.7rem;
  align-items: center
}

.jwc-lportal .user-profile-container .sub_tab_container2 .activity_content .text-content .address .theater-name {
  margin-bottom: 0
}

.jwc-lportal .user-profile-container .sub_tab_container2 .activity_content .text-content .address .theater-name strong {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .user-profile-container .sub_tab_container2 .activity_content .text-content .address .tickets {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847;
  margin-bottom: 0
}

.jwc-lportal .user-profile-container .sub_tab_container2 .activity_content .text-content .address .tickets strong {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847;
  margin-bottom: 0
}

.jwc-lportal .user-profile-container .sub_tab_container2 img {
  padding-right: .5rem
}

.jwc-lportal .user-profile-container .sub_tab_container2.sub_tab_block {
  display: none
}

.jwc-lportal .user-profile-container .sub_tab_container2.active-tab {
  display: inherit
}

.jwc-lportal .user-profile-container .sub_tab_container2 .view-past-transaction-btn {
  text-align: right;
  padding: 2rem 0
}

.jwc-lportal .user-profile-container .sub_tab_container2 .view-past-transaction-btn .cta-prim {
  font-family: "montserrat";
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  font-style: normal;
  text-align: left;
  color: #2c2c2c
}

.jwc-lportal .user-profile-container .sub_tab_container2 .view-past-transaction-btn .cta-prim:hover {
  color: #fff
}

.jwc-lportal .user-profile-container .sub_tab_container3 {
  padding: 2rem;
  background-color: #fff !important
}

.jwc-lportal .user-profile-container .sub_tab_container3 .slider_box {
  box-shadow: 0px 2px 3px 1px rgba(136, 136, 136, .1215686275)
}

.jwc-lportal .user-profile-container .sub_tab_container3 .highlights_wrapper_container_2 .slider_text_color {
  background-color: var(--data-gcategory-bgColor)
}

@media screen and (max-width: 500px) {
  .jwc-lportal .user-profile-container .sub_tab_container3 .swiper-container .cta-sec.color-pink:after {
      display: none
  }

  .jwc-lportal .user-profile-container .sub_tab_container3 .swiper-container .cta-prim-txt {
      border: 1.5px solid #c63493;
      padding: 1rem 2rem
  }

  .jwc-lportal .user-profile-container .sub_tab_container3 .swiper-container .swiper-arrows {
      display: block
  }
}

.jwc-lportal .user-profile-container .sub_tab_container3.sub_tab_block {
  display: none
}

.jwc-lportal .user-profile-container .sub_tab_container3.active-tab {
  display: inherit
}

.jwc-lportal .user-profile-container .user_profile_content {
  background-color: #fff;
  padding: 1.5rem 0
}

.jwc-lportal .user-profile-container .user_profile_content .info {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  align-items: center
}

.jwc-lportal .user-profile-container .user_profile_content .info p {
  margin-bottom: 0;
  font-size: 18px
}

.jwc-lportal .user-profile-container .user_profile_content .info .profile-options {
  display: flex;
  -moz-column-gap: 2rem;
  column-gap: 2rem
}

.jwc-lportal .user-profile-container .user_profile_content .info .profile-options .save-profile {
  display: flex;
  color: #c92c99;
  align-items: center;
  -moz-column-gap: .5rem;
  column-gap: .5rem
}

.jwc-lportal .user-profile-container .user_profile_content .info .profile-options .save-profile img {
  max-width: 21px;
  width: 100%;
  height: 18px
}

.jwc-lportal .user-profile-container .user_profile_content .info .profile-options .save-profile p {
  font-size: 14px;
  font-family: "Montserrat", serif;
  margin-bottom: 0;
  font-weight: 600
}

.jwc-lportal .user-profile-container .user_profile_content .info .profile-options .edit-profile {
  display: flex;
  color: #c92c99;
  align-items: center;
  -moz-column-gap: .5rem;
  column-gap: .5rem
}

.jwc-lportal .user-profile-container .user_profile_content .info .profile-options .edit-profile img {
  max-width: 21px;
  width: 100%;
  height: 18px
}

.jwc-lportal .user-profile-container .user_profile_content .info .profile-options .edit-profile p {
  font-size: 14px;
  font-family: "Montserrat", serif;
  margin-bottom: 0;
  font-weight: 600
}

.jwc-lportal .user-profile-container .user_profile_content .user-details {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem 1.5rem
}

.jwc-lportal .user-profile-container .user_profile_content .user-details input[type=text] {
  padding: 1rem 2rem;
  border: 1px solid #e3dcd0
}

.jwc-lportal .user-profile-container .user_profile_content .user-details input[type=text]:focus-visible {
  border: 1px solid #e0d4be !important
}

.jwc-lportal .user-profile-container .user_profile_content .user-details input[type=number] {
  padding: 1rem 2rem;
  border: 1px solid #e3dcd0
}

.jwc-lportal .user-profile-container .user_profile_content .user-details input[type=number]:focus-visible {
  border: 1px solid #e0d4be !important
}

.jwc-lportal .user-profile-container .user_profile_content.page_content {
  display: none
}

.jwc-lportal .user-profile-container .user_profile_content.currentSubTab {
  display: block
}

.jwc-lportal .slider_wrapper .slider_content_wrapper .custom-txt {
  padding-bottom: 10px !important
}

.jwc-lportal .slider_wrapper .slider_content_wrapper .highlights_wrapper_each_copy--text {
  padding-bottom: 20px
}

.jwc-lportal .slider_wrapper .slider_content_wrapper .seats-number {
  padding-bottom: 10px
}

.jwc-lportal .booking_section {
  background-color: rgba(245, 242, 237, .5019607843)
}

.jwc-lportal .booking_section .tab-container .tab_wrapper {
  padding: 0 2.2rem
}

.jwc-lportal .booking_section .tab-container .tab_list_center {
  display: flex;
  justify-content: center;
  background-color: #f5f2ed
}

.jwc-lportal .booking_section .tab-container .tabs {
  list-style: none;
  display: flex;
  padding: 1.5rem;
  margin-bottom: 0rem
}

.jwc-lportal .booking_section .tab-container .tabs li {
  padding-left: 1rem;
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  color: #a67d54
}

.jwc-lportal .booking_section .tab-container .tabs li:first-child {
  border-right: 1px solid;
  padding-right: 1rem;
  border-right-color: #a67d54
}

.jwc-lportal .booking_section .tab-container .tabs .current {
  color: #c92c99
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .booking_section .tab-container .tab_wrapper {
      padding: 0 3.2rem
  }
}

@media only screen and (max-width: 600px) {
  .jwc-lportal .booking_section .tab-container .tab_wrapper {
      padding: 0 2.2rem
  }
}

.jwc-lportal .booking_section .tab-content {
  display: none
}

.jwc-lportal .booking_section .tab-content.preference-content {
  padding: 0 1.3rem
}

.jwc-lportal .booking_section .tab-content .content {
  background-color: #fff;
  padding: 0 1.5rem;
  margin-bottom: 80px
}

.jwc-lportal .booking_section .tab-content .content p {
  font-size: 24px
}

.jwc-lportal .booking_section .tab-content.current {
  display: inherit
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .booking_section .tab-content.current {
      display: block !important
  }

  .jwc-lportal .booking_section .tab-content .user_profile_filters {
      max-width: calc(100% - 40px)
  }
}

.jwc-lportal .booking_section .booking-content-block h4 {
  margin-bottom: 4px
}

.jwc-lportal .booking_section .booking-content-block .highlights_wrapper_each_copy--text {
  padding-bottom: 20px
}

.jwc-lportal .booking_section .booking-content-block .seats-number {
  padding-bottom: 15px
}

.jwc-lportal .recommendation_container {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.jwc-lportal .recommendation_container .slider_box {
  box-shadow: 0px 2px 3px 1px rgba(136, 136, 136, .1215686275)
}

.jwc-lportal .recommendation_container .checklist {
  margin-top: 3rem
}

.jwc-lportal .recommendation_container .checklist .custom-form-check .recommend_checkBox {
  font-weight: 500;
  background: rgba(0, 0, 0, 0);
  color: #c92c99
}

.jwc-lportal .recommendation_container .checklist .custom-form-check .recommend_checkBox .custom-control-label::before {
  border: 2px solid #c92c99
}

@media screen and (max-width: 768px) {
  .jwc-lportal .recommendation_container {
      flex-direction: column;
      align-items: flex-start
  }

  .jwc-lportal .recommendation_container .section_heading {
      padding-bottom: 10px
  }

  .jwc-lportal .recommendation_container .checklist {
      margin-top: 1rem;
      padding: 0 2rem 2.5rem
  }
}

.jwc-lportal .recommendation_content .custom-recommend-container {
  padding: 2rem 0
}

.jwc-lportal .recommendation_content .custom-recommend-container .recommendation-slide .copy-container {
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .0784313725)
}

.jwc-lportal .recommendation_content .custom-recommend-container .recommendation-slide .copy-container .content3 {
  background: #fff
}

.jwc-lportal .recommendation_content .slider_box {
  box-shadow: 0px 2px 3px 1px rgba(136, 136, 136, .1215686275)
}

@media screen and (max-width: 500px) {
  .jwc-lportal .recommendation_content .swiper-container .swiper-arrows {
      display: block
  }
}

.jwc-lportal .past_transaction_section1 {
  background: var(--data-past-transaction-bgColor);
  padding-bottom: 0
}

.jwc-lportal .past_transaction_section1 .past-transaction-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem
}

.jwc-lportal .past_transaction_section1 .past-trans-category .wishlist_wrapper_list {
  padding: 0;
  background: #fff
}

@media only screen and (min-width: 769px)and (max-width: 992px) {
  .jwc-lportal .past_transaction_section1 .wishlist_wrapper_list .filters {
      max-width: calc(100% - 0px)
  }
}

@media screen and (max-width: 768px) {
  .jwc-lportal .past_transaction_section1 .past-transaction-heading {
      flex-direction: column;
      row-gap: 1rem;
      align-items: flex-start
  }

  .jwc-lportal .past_transaction_section1 .past-transaction-heading h2 {
      font-size: 32px;
      line-height: 36px;
      max-width: 100%
  }

  .jwc-lportal .past_transaction_section1 .past-trans-category .wishlist_wrapper_list .filters {
      max-width: calc(100% - 0px)
  }
}

.jwc-lportal .past_transaction_list {
  background: var(--data-past-transaction-bgColor);
  padding-top: 0
}

.jwc-lportal .past_transaction_list .grand-theater-list {
  display: flex;
  align-items: center;
  -moz-column-gap: 8.5rem;
  column-gap: 8.5rem;
  background: #fff;
  padding: 1.5rem 2rem;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, .05);
  margin: 1.5rem 0
}

.jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content {
  display: flex;
  align-items: center;
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem
}

.jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .main-title {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847;
  margin-bottom: 5px
}

.jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .address .theater-name {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px
}

.jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .address .theater-name strong {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .address .tickets {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847;
  margin-bottom: 0
}

.jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .address .tickets strong {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847;
  margin-bottom: 0
}

.jwc-lportal .past_transaction_list .book-hotel-list {
  display: flex;
  align-items: center;
  -moz-column-gap: 7rem;
  column-gap: 7rem;
  background: #fff;
  padding: 1.5rem 2rem;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, .05);
  margin: 2rem 0
}

.jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content {
  display: flex;
  align-items: center;
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem
}

.jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details {
  max-width: 650px;
  width: 100%
}

.jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .main-title {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847;
  margin-bottom: 5px
}

.jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .address .restaurant-name {
  margin-bottom: 0
}

.jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .address .restaurant-name strong {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .address .tickets {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847;
  margin-bottom: 0
}

.jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .address .tickets strong {
  font-weight: 600;
  line-height: 24px
}

@media screen and (max-width: 1199px) {
  .jwc-lportal .past_transaction_list .grand-theater-list {
      -moz-column-gap: 3rem;
      column-gap: 3rem
  }

  .jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content {
      max-width: 600px;
      width: 100%
  }

  .jwc-lportal .past_transaction_list .book-hotel-list .cta-prim {
      white-space: nowrap
  }
}

@media only screen and (min-width: 769px)and (max-width: 992px) {
  .jwc-lportal .past_transaction_list .grand-theater-list {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 1.5rem
  }

  .jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content {
      max-width: initial;
      width: 100%
  }

  .jwc-lportal .past_transaction_list .book-hotel-list {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 1.5rem
  }

  .jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content {
      width: 100%
  }
}

@media screen and (max-width: 768px) {
  .jwc-lportal .past_transaction_list .grand-theater-list {
      flex-direction: column;
      row-gap: 1rem;
      padding: 1.5rem 1rem;
      margin: .5rem
  }

  .jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content {
      -moz-column-gap: 1rem;
      column-gap: 1rem;
      width: 100%;
      align-items: flex-start;
      max-width: initial
  }

  .jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .main-title {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px
  }

  .jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .address .theater-name {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .address .theater-name strong {
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .address .tickets {
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .past_transaction_list .grand-theater-list .grand-theater-content .theater-details .address .tickets strong {
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .past_transaction_list .book-hotel-list {
      flex-direction: column;
      row-gap: 1rem;
      padding: 1.5rem 1rem;
      margin: .5rem
  }

  .jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content {
      -moz-column-gap: 1rem;
      column-gap: 1rem;
      align-items: flex-start
  }

  .jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .main-title {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px
  }

  .jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .address .restaurant-name {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .address .restaurant-name strong {
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .address .tickets {
      font-size: 14px;
      line-height: 24px
  }

  .jwc-lportal .past_transaction_list .book-hotel-list .book-hotel-content .hotel-details .address .tickets strong {
      font-size: 14px;
      line-height: 24px
  }
}

.jwc-lportal .search_result_section .search_section_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 45px
}

.jwc-lportal .search_result_section .search-pages {
  color: #2c2c2c;
  padding-top: .5rem
}

.jwc-lportal .search_result_section .search-page-content-block {
  border-bottom: 1px solid rgba(112, 112, 112, .4509803922);
  padding: 2rem 0
}

.jwc-lportal .search_result_section .search-page-content-block span {
  font-family: "montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  font-style: normal;
  text-align: left;
  letter-spacing: 2px;
  color: #c63493
}

.jwc-lportal .search_result_section .search-page-content-block h5 {
  padding-top: 1rem;
  font-family: "montserrat";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  font-style: normal;
  text-align: left;
  color: #2c2c2c
}

.jwc-lportal .search_result_section .search-page-content-block p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .search_result_section .search-page-content-block .cta-sec {
  display: inline-block;
  text-decoration: none
}

.jwc-lportal .search_result_section .search-page-content-block .cta-sec span {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: all ease-out .25s;
  letter-spacing: 1.5px;
  color: #4b4847
}

.jwc-lportal .search_result_section .search_page_swiper .search-page-slider-content {
  display: flex;
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem
}

.jwc-lportal .search_result_section .search_page_swiper .search-page-slider-content .slider-content span {
  font-family: "montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  font-style: normal;
  text-align: left;
  color: #c63493
}

.jwc-lportal .search_result_section .search_page_swiper .search-page-slider-content .slider-content p {
  padding-top: 15px;
  font-family: "montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .search_result_section .search_page_swiper .search-page-slider-content .slider-content .load-more .cta-sec span {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: capitalize;
  transition: all ease-out .25s;
  color: #4b4847
}

.jwc-lportal .search_result_section .topics-btn-container {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.jwc-lportal .search_result_section .search_topic_btn_slider .search-tabs {
  padding: 1.5rem 0
}

.jwc-lportal .search_result_section .search_topic_btn_slider .search-tabs .search-topics-btn {
  cursor: pointer;
  background: #fff;
  border: 0;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  color: #c63493;
  padding: 15px 30px;
  box-shadow: 4px 8px 12px rgba(163, 163, 163, .2)
}

.jwc-lportal .search_result_section .search_topic_btn_slider .search-tabs .search-topics-btn:focus {
  outline: none
}

@media screen and (max-width: 768px) {
  .jwc-lportal .search_result_section .search_section_heading {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 2rem
  }

  .jwc-lportal .search_result_section .search_page_swiper .search-page-slider-content {
      display: flex;
      -moz-column-gap: .7rem;
      column-gap: .7rem
  }

  .jwc-lportal .search_result_section .search_page_swiper .search-page-slider-content .img {
      max-width: 50%;
      width: 100%
  }

  .jwc-lportal .search_result_section .search_page_swiper .search-page-slider-content .img img {
      width: 100%;
      height: 100%
  }

  .jwc-lportal .search_result_section .search_page_swiper .search-page-slider-content .slider-content p {
      padding-top: 5px
  }

  .jwc-lportal .search_result_section .search-page-content-block span {
      font-weight: bold
  }
}

.jwc-lportal .dining-page-section2 {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: var(--data-events-bgColor)
}

.jwc-lportal .dining-page-section2 .dining-page-row-bg {
  background: #fff
}

.jwc-lportal .dining-page-section2 .dining-page-row-bg .dining-text-container h5 {
  font-family: "montserrat";
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .dining-page-section2 .dining-page-row-bg .dining-page-list-tag .pink-text-tag {
  font-family: "montserrat";
  font-size: 12px;
  font-weight: bold;
  line-height: 34px;
  font-style: normal;
  text-align: left;
  color: #c92c99;
  text-transform: uppercase
}

.jwc-lportal .dining-page-section3 .preorder-custom-row {
  justify-content: space-between;
  padding-left: 1rem;
  align-items: center;
  margin-bottom: 3rem
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container {
  display: flex;
  -moz-column-gap: 2rem;
  column-gap: 2rem
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container .preorder-checkbox>input {
  opacity: 0;
  position: absolute;
  z-index: -1
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container .preorder-checkbox>input:checked+label {
  color: #c92c99
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container .preorder-checkbox>input:checked+label::before {
  background: rgba(232, 86, 163, .25);
  border-color: #e856a3
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container .preorder-checkbox>input:checked+label::after {
  transform: scale(1) rotate(-45deg)
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container .preorder-checkbox>input:checked+label a {
  text-decoration: underline
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container .preorder-checkbox>label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: all .1s;
  font-family: "montserrat";
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  font-style: normal;
  text-align: left;
  color: #c92c99
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container .preorder-checkbox>label::before {
  background: #d8d8d8;
  border-radius: calc(1px + .02em);
  content: "";
  display: inline-block;
  font-size: 3rem;
  height: calc(3px + .8em);
  margin-right: calc(1px + .2em);
  transition: all .1s;
  vertical-align: middle;
  width: calc(3px + .8em)
}

.jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container .preorder-checkbox>label::after {
  border-bottom: calc(2px + .07em) solid #c92c99;
  border-left: calc(2px + .07em) solid #c92c99;
  content: "";
  font-size: 3rem;
  height: calc(3px + .22em);
  left: calc(.25em - 1px);
  position: absolute;
  top: calc(50% - .1em - 3px);
  transform: scale(0) rotate(-45deg);
  transition: all .1s;
  width: calc(5px + .32em)
}

.jwc-lportal .dining-page-section3 .row .dining-text-container .dining-page-list-tag {
  padding-bottom: 1.5rem
}

.jwc-lportal .dining-page-section3 .row .dining-text-container .dining-page-list-tag p {
  font-family: "montserrat";
  font-size: 12px;
  font-weight: bold;
  line-height: 34px;
  font-style: normal;
  text-align: left;
  color: #c92c99;
  text-transform: uppercase
}

@media screen and (max-width: 768px) {
  .jwc-lportal .dining-page-section3 .preorder-section-heading {
      font-family: "montserrat";
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      font-style: normal;
      text-align: left;
      color: #4b4847
  }

  .jwc-lportal .dining-page-section3 .preorder-custom-row .preorder-checkbox-container {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem
  }

  .jwc-lportal .dining-page-section3 .preorder-custom-row .pre_order_button {
      display: none
  }
}

.jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-text-container h2 {
  font-family: "montserrat";
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-text-container p {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box {
  display: flex;
  white-space: nowrap
}

.jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box .input-field {
  max-width: 240px
}

.jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box .input-field:focus-visible {
  outline: none
}

.jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box .cta-prim {
  margin-left: -3px;
  padding: 22px 30px
}

@media screen and (max-width: 768px) {
  .jwc-lportal .benefits-privilege-section .benefits-privilege-row {
      flex-direction: row !important
  }

  .jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-text-container h2 {
      font-size: 32px;
      line-height: 40px
  }

  .jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-text-container p {
      font-size: 16px;
      line-height: 27px
  }

  .jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box {
      display: flex
  }

  .jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box .input-field {
      max-width: 150px
  }

  .jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box .cta-prim {
      padding: 12px 30px;
      margin-top: 0px
  }

  .jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box .cta-prim .cta-prim-txt {
      white-space: nowrap;
      letter-spacing: 1px
  }
}

@media screen and (max-width: 550px) {
  .jwc-lportal .benefits-privilege-section .benefits-privilege-row .benefits-privilege-btn .input-box .input-field {
      max-width: 190px
  }
}

.jwc-lportal .keeping-you-safe-section {
  padding-top: 90px;
  background: var(--data-background-color)
}

.jwc-lportal .keeping-you-safe-section .section_heading h2 {
  font-family: "montserrat";
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .keeping-you-safe-section .keeping-you-safe-custom-main-row .custom-sub-row .custom-img-col {
  padding-right: 5px;
  text-align: center
}

.jwc-lportal .keeping-you-safe-section .keeping-you-safe-custom-main-row .custom-sub-row .custom-content-col {
  padding-left: 5px
}

.jwc-lportal .keeping-you-safe-section .keeping-you-safe-custom-main-row .custom-sub-row .custom-content-col h3 {
  font-family: "montserrat";
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .keeping-you-safe-section .keeping-you-safe-custom-main-row .custom-sub-row .custom-content-col p {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

@media screen and (max-width: 768px) {
  .jwc-lportal .keeping-you-safe-section .section_heading h2 {
      font-size: 32px;
      line-height: 48px
  }

  .jwc-lportal .keeping-you-safe-section .keeping-you-safe-custom-main-row .custom-sub-row .custom-img-col {
      width: 25%;
      padding-right: 5px;
      text-align: center
  }

  .jwc-lportal .keeping-you-safe-section .keeping-you-safe-custom-main-row .custom-sub-row .custom-content-col {
      width: 75%;
      padding-left: 5px
  }

  .jwc-lportal .keeping-you-safe-section .keeping-you-safe-custom-main-row .custom-sub-row .custom-content-col h3 {
      font-family: "montserrat";
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      font-style: normal;
      text-align: left;
      color: #4b4847
  }

  .jwc-lportal .keeping-you-safe-section .keeping-you-safe-custom-main-row .custom-sub-row .custom-content-col p {
      font-family: "montserrat";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-style: normal;
      text-align: left;
      color: #4b4847
  }
}

.jwc-lportal .dining-details-section1 {
  background-color: var(--data-events-bgColor)
}

.jwc-lportal .dining-details-section1 .dine-details-section-heading {
  font-family: "montserrat";
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-7 p {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-7 .buttons {
  padding-top: 2rem
}

.jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-4 .content-head {
  font-family: "montserrat";
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-4 p {
  font-family: "montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-4 p a {
  color: #c92c99;
  text-decoration: underline
}

@media screen and (max-width: 768px) {
  .jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-7 .buttons {
      padding-top: 0rem
  }

  .jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-4 {
      padding-top: 45px
  }

  .jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-4 .venue_details {
      align-items: flex-start
  }

  .jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-4 .content-head {
      font-size: 22px;
      font-weight: 400;
      line-height: 26px
  }

  .jwc-lportal .dining-details-section1 .dining-details-custom-row .custom-col-4 p {
      font-size: 15px;
      line-height: 25px
  }
}

.jwc-lportal .dining-details-section2 {
  background-color: var(--data-events-bgColor)
}

.jwc-lportal .dining-details-section2 .dine-details-section-heading {
  font-family: "montserrat";
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .dining-details-section2 .dining-page-row-bg {
  background: #fff
}

.jwc-lportal .dining-details-section2 .dining-page-row-bg .dining-text-container h5 {
  font-family: "montserrat";
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .dining-details-section2 .dining-page-row-bg .dining-page-list-tag .pink-text-tag {
  font-family: "montserrat";
  font-size: 12px;
  font-weight: bold;
  line-height: 34px;
  font-style: normal;
  text-align: left;
  color: #c92c99;
  text-transform: uppercase
}

.jwc-lportal .getting-here-section-1 {
  background-color: var(--data-events-bgColor);
  padding: 60px 0 90px
}

.jwc-lportal .getting-here-section-1 .getting-here-head-text {
  font-family: "montserrat";
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

@media screen and (max-width: 768px) {
  .jwc-lportal .getting-here-section-1 .col-md-5 {
      padding-top: 2rem
  }

  .jwc-lportal .getting-here-section-1 .col-md-5 .getting-here-head-text {
      font-size: 22px;
      font-weight: 400;
      line-height: 26px
  }
}

.jwc-lportal .getting-here-section-2 {
  background-color: var(--data-events-bgColor);
  padding: 90px 0
}

.jwc-lportal .getting-here-section-2 .section_heading {
  padding-bottom: 10px
}

.jwc-lportal .getting-here-section-2 .cus_accordion .card {
  background-color: var(--data-events-bgColor);
  border-bottom: none
}

.jwc-lportal .getting-here-section-2 .cus_accordion .card .card-header .content1 {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  font-style: normal;
  text-align: left;
  color: #000
}

@media screen and (max-width: 768px) {
  .jwc-lportal .getting-here-section-2 .cus_accordion .card {
      background-color: var(--data-events-bgColor);
      border-bottom: none
  }

  .jwc-lportal .getting-here-section-2 .cus_accordion .card .card-header .content1 {
      font-family: "montserrat";
      font-size: 18px;
      font-weight: 500;
      line-height: 31px;
      font-style: normal;
      text-align: left;
      color: #000
  }
}

.jwc-lportal .performing-art-section1 {
  padding: 1.7rem 0
}

.jwc-lportal .performing-art-section1 .custom-performing-art-row {
  display: flex;
  align-items: center
}

.jwc-lportal .performing-art-section1 .custom-performing-art-row .col-md-9 h4 {
  font-family: "montserrat";
  font-size: 40px;
  font-weight: 400;
  line-height: 42px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .performing-art-section1 .custom-performing-art-row .col-md-3 .whats-on-btn {
  display: flex;
  justify-content: flex-end
}

@media screen and (max-width: 768px) {
  .jwc-lportal .performing-art-section1 .cta-prim {
      width: 100%
  }
}

.jwc-lportal .performing-art-section2 {
  background: #fff
}

.jwc-lportal .performing-art-section2 .section_heading {
  font-family: "montserrat";
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .performing-art-section2 .cta-holder {
  top: -5rem;
  right: 15%
}

.jwc-lportal .performing-art-section2 .custom-performing-heading-row {
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-bottom: 1.5rem
}

.jwc-lportal .performing-art-section2 .feature_shows_slider .swiper-slide-active .feature_shows_slider_each--img {
  top: 30px
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each--img {
  margin: 0 30px
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy {
  background: #fff;
  padding: 50px 30px 25px;
  font-family: "Montserrat", sans-serif;
  transition: .5s ease-in-out .35s;
  position: relative;
  opacity: 0;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, .0588235294);
  margin-bottom: 30px
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy .cta-prim.icon:before {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 27px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
  z-index: 1
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy .icon-ticket:before {
  content: ""
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy [class^=icon-]:before,
.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy [class*=" icon-"]:before {
  font-family: "jwc-icon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy .cta-prim.color-pink:after {
  background: #c5299b
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy .cta-prim.icon:hover {
  color: #fff;
  background: #c5299b
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy .cta-prim:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all ease-out .25s
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy .date-time-container {
  padding-top: 1rem
}

.jwc-lportal .performing-art-section2 .feature_shows_slider_each_copy .date-time-container p {
  margin-bottom: 0
}

@media screen and (max-width: 768px) {
  .jwc-lportal .performing-art-section2 .custom-performing-heading-row {
      padding: 0 2rem
  }

  .jwc-lportal .performing-art-section2 .feature_shows_slider .swiper-slide-active .feature_shows_slider_each--img {
      top: 0px
  }

  .jwc-lportal .performing-art-section2 .swiper-slide-active {
      padding-left: 1rem
  }

  .jwc-lportal .performing-art-section2 .feature_shows_slider_each--img {
      margin: 0px
  }

  .jwc-lportal .performing-art-section2 .cta-holder {
      padding-right: 4rem
  }
}

.jwc-lportal .performing-art-section3 {
  padding-bottom: 1rem
}

.jwc-lportal .performing-art-section3 .section_heading {
  padding-bottom: 10px
}

.jwc-lportal .performing-arts-calender-section {
  background: var(--data-background-color)
}

.jwc-lportal .performing-arts-calender-section .whats_on_categories_wrapper .whats_on_categories_wrapper_list {
  border-left: none !important;
  border-right: none !important
}

.jwc-lportal .performing-arts-calender-section .whats_on_categories_wrapper .whats_on_categories_wrapper_list_each {
  border-right: none
}

.jwc-lportal .performing-arts-calender-section .whats_on_categories_wrapper .whats_on_categories_wrapper_list_each .custom-reset-btn {
  padding: 1.5rem
}

.jwc-lportal .performing-arts-calender-section .whats_on_categories_wrapper .reset-desktop-view {
  display: block
}

.jwc-lportal .performing-arts-calender-section .reset-mobile-view {
  display: none
}

@media screen and (max-width: 768px) {
  .jwc-lportal .performing-arts-calender-section .whats_on_categories_wrapper .reset-desktop-view {
      display: none
  }

  .jwc-lportal .performing-arts-calender-section .reset-mobile-view {
      display: block
  }
}

.jwc-lportal .performing-arts-benefits-section {
  padding-bottom: 2rem
}

.jwc-lportal .visual-art-short_details .event_short_details_2_title h4 {
  font-family: "montserrat";
  font-size: 40px;
  font-weight: 400;
  line-height: 42px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .visual-art-archive-section {
  padding: 5.5rem 0;
  background: var(--data-events-bgColor1)
}

.jwc-lportal .visual-arts-moment-section .moment-slider .moment-slider-col {
  padding-left: 0;
  padding-right: 0
}

.jwc-lportal .visual-art-advisory-board-section .section_heading h2 {
  font-family: "montserrat";
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .visual-art-advisory-board-section .section_heading p {
  font-family: "montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .visual-art-advisory-board-section .advisory-board-card {
  background: #fff;
  padding: 1rem 1.5rem;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, .06)
}

.jwc-lportal .visual-art-advisory-board-section .advisory-board-card p {
  font-family: "montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #4b4847
}

.jwc-lportal .page-not-found-banner-section .kma_text_wrapper h1 {
  font-family: "montserrat";
  font-size: 90px;
  font-weight: 500;
  line-height: 82px;
  font-style: normal;
  text-align: center;
  color: #fff
}

.jwc-lportal .page-not-found-banner-section .kma_text_wrapper .return-options {
  display: flex;
  justify-content: center;
  -moz-column-gap: 2.5rem;
  column-gap: 2.5rem
}

@media screen and (max-width: 768px) {
  .jwc-lportal .page-not-found-banner-section .kma_text_wrapper h1 {
      font-size: 38px;
      font-weight: 500;
      line-height: 47px;
      font-style: normal;
      text-align: center;
      color: #fff
  }

  .jwc-lportal .page-not-found-banner-section .kma_text_wrapper p {
      font-family: "montserrat";
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      font-style: normal;
      text-align: center;
      color: #fff
  }

  .jwc-lportal .page-not-found-banner-section .kma_text_wrapper .return-options {
      flex-direction: column;
      row-gap: 1.5rem
  }
}

.jwc-lportal .page-404-useful-link-section .page-not-found-useful-link .useful-link-list {
  margin-bottom: 65px;
  box-shadow: 0px 8px 9px rgba(163, 163, 163, .22)
}

.jwc-lportal .page-404-useful-link-section .page-not-found-useful-link .useful-link-list .usefull-link-contents {
  padding: 20px;
  background: #fff;
  margin: 0;
  border-bottom: none
}

@media screen and (max-width: 768px) {
  .jwc-lportal .page-404-useful-link-section .section_heading {
      padding-left: 15px
  }
}

.jwc-lportal .pagination_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 30px
}

.jwc-lportal .pagination_row .pagination_col {
  width: 18px;
  height: 18px;
  background: #fcedf4;
  border-radius: 50%;
  position: relative;
  border: 1px solid #c63493
}

.jwc-lportal .pagination_row .pagination_col span {
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: #fff
}

.jwc-lportal .pagination_row .pagination_col.active {
  width: 38px;
  height: 38px;
  background: #c63493
}

.jwc-lportal .pagination_row .pagination_col.active span {
  opacity: 1;
  font-size: 20px
}

@media only screen and (max-width: 650px) {
  .jwc-lportal .pagination_row {
      justify-content: center
  }
}

.jwc-lportal .pagination_arrows {
  position: absolute;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
  gap: 20px;
  top: -80px;
  right: 0
}

.jwc-lportal .pagination_arrows a {
  height: 12px;
  width: 40px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .5
}

.jwc-lportal .pagination_arrows a:hover,
.jwc-lportal .pagination_arrows a:focus {
  opacity: 1;
  text-decoration: none
}

.jwc-lportal .pagination_arrows a:after {
  content: "";
  font-family: "jwc-icon" !important;
  font-size: 13px;
  transition: .25s ease-in-out;
  color: #c63493
}

.jwc-lportal .pagination_arrows a.disabled {
  pointer-events: none
}

.jwc-lportal .pagination_arrows a.disabled:after {
  color: #acacac
}

.jwc-lportal .pagination_arrows a.next_page:after {
  content: ""
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .pagination_arrows {
      top: -75px
  }
}

.jwc-lportal .icon-white img {
  filter: brightness(1) !important
}

.jwc-lportal .icon-white:hover img,
.jwc-lportal .icon-white:focus img {
  filter: brightness(20) !important
}

.jwc-lportal #register_form .modal-dialog {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;
  display: grid;
  align-items: flex-end;
  transform: translate(0, 25%)
}

.jwc-lportal #register_form.modal.show .modal-dialog {
  transform: translate(0, 0%)
}

.jwc-lportal #register_form .modal-body {
  padding: 0
}

.jwc-lportal #register_form .pre_register_form {
  padding: 50px 0 !important
}

@media only screen and (max-width: 500px) {
  .jwc-lportal #register_form .pre_register_form .section_heading {
      max-width: 100%
  }

  .jwc-lportal #register_form .pre_register_form .jwc_form {
      margin-top: 2rem !important
  }
}

.jwc-lportal #register_form .modal-content,
.jwc-lportal #register_form .modal-body {
  height: -moz-fit-content;
  height: fit-content
}

.jwc-lportal #register_form .modal-content {
  border: 0;
  border-radius: 0
}

.jwc-lportal #register_form .dropdown .dropdown--list {
  z-index: 2
}

.jwc-lportal .ml-6 {
  margin-left: 2rem
}

.jwc-lportal .swiper_pagination_left .swiper-pagination {
  max-width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 10px;
  text-align: left
}

.jwc-lportal .breadcrumbs .container {
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: nowrap
}

.jwc-lportal .reset_filter {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding: 0 20px
}

@media only screen and (min-width: 991px) {
  .jwc-lportal .reset_filter {
      margin-top: -50px;
      margin-bottom: 60px;
      text-align: center
  }
}

.jwc-lportal .cta-prim.icon:before {
  display: none
}

.jwc-lportal .cta-prim.icon img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  filter: brightness(20);
  width: 18px;
  left: 24px
}

.jwc-lportal .cta-prim.icon:hover img,
.jwc-lportal .cta-prim.icon:focus img {
  filter: brightness(1);
  animation: iconAnimation .3s 1 linear
}

.jwc-lportal .icon-download-icon.icon-download-icon-arrow:before {
  display: none
}

.jwc-lportal .icon-download-icon.icon-download-icon-arrow img {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  font-size: 20px;
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
  object-fit: contain;
  right: 0px
}

@media only screen and (max-width: 768px) {
  .jwc-lportal .icon-download-icon.icon-download-icon-arrow img {
      position: relative;
      display: block;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      margin: 0 auto 10px
  }
}

.jwc-lportal .cta_holder_flex {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px
}

.jwc-lportal .cta_holder_flex .section_heading {
  padding-bottom: 0
}

.jwc-lportal .cta_holder_flex .section_heading h2 {
  margin-bottom: 0;
  padding-bottom: 0
}

@media only screen and (max-width: 500px) {
  .jwc-lportal .cta_holder_flex .section_heading h2 {
      max-width: 250px
  }
}

.jwc-lportal footer .jwc_footer_top_inner {
  flex-direction: column
}

.jwc-lportal footer .jwc_footer_top_inner ul+ul {
  margin-top: 20px;
  max-width: 1000px
}

@media only screen and (min-width: 768px) {
  .jwc-lportal footer .jwc_footer_top_inner ul+ul li {
      margin-bottom: 20px
  }
}

.jwc-lportal footer .social--UL a:hover svg path,
.jwc-lportal footer .social--UL a:hover svg circle,
.jwc-lportal footer .social--UL a:focus svg path,
.jwc-lportal footer .social--UL a:focus svg circle {
  fill: #4a4847 !important
}

@media(min-width: 1000px)and (max-width: 1599px) {
  .jwc-lportal footer .jwc_footer_bar_inner {
      padding: 0 20px
  }
}

@media only screen and (max-width: 767px) {
  .jwc-lportal .desktop_only {
      display: none !important
  }

  .jwc-lportal .jwc_swiper_normal {
      padding: 0
  }

  .jwc-lportal .jwc_swiper_normal.jwc_swiper_normal_p20 {
      padding-left: 20px
  }

  .jwc-lportal .jwc_swiper_normal.jwc_image_slider {
      padding-left: 0
  }
}

@media only screen and (max-width: 1000px) {
  .jwc-lportal .jwc_swiper_normal.jwc_swiper_normal_p20 {
      padding-left: 20px
  }
}

@media only screen and (min-width: 768px) {
  .jwc-lportal .mobile_only {
      display: none !important
  }
}

@media only screen and (max-width: 991px) {
  .jwc-lportal main {
      /* margin-top: 60px */
  }
}

@media only screen and (min-width: 576px)and (max-width: 768px) {
  .jwc-lportal .whats_on_categories .whats_on_categories_wrapper.filter_view .filter_view_tab {
      max-width: 540px;
      margin-left: auto;
      margin-right: auto
  }

  .jwc-lportal .whats_on_categories .whats_on_categories_wrapper.filter_view .filter_view_tab .listFilter {
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb
  }

  .jwc-lportal .jwc_banner_slider {
      min-height: auto
  }

  .jwc-lportal .jwc_banner_slider .text-container-banner-slider {
      padding: 50px 40px 100px
  }
}

@media only screen and (min-width: 600px)and (max-width: 800px) {
  .jwc-lportal .jwc_footer_bar .jwc_footer_bar_inner {
      flex-wrap: wrap
  }

  .jwc-lportal .jwc_footer_bar .jwc_footer_bar_inner .col-md-4 {
      text-align: center;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%
  }

  .jwc-lportal .jwc_footer_bar .jwc_footer_bar_inner .col-md-4:not(:first-child) {
      margin-top: 20px
  }

  .jwc-lportal .jwc_footer_bar .jwc_footer_bar_inner .col-md-4 .links {
      grid-template-columns: repeat(3, 1fr)
  }

  .jwc-lportal .jwc_footer_bar .jwc_footer_bar_inner .col-md-4 .links li {
      text-align: center
  }

  .jwc-lportal .jwc_footer_bar .jwc_footer_bar_inner .col-md-4.social {
      justify-content: center !important
  }
}

@media only screen and (min-width: 768px)and (max-width: 986px) {
  .jwc-lportal .whats_on_categories .whats_on_categories_wrapper.filter_view .filter_view_tab {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto
  }

  .jwc-lportal .whats_on_categories .whats_on_categories_wrapper.filter_view .filter_view_tab .listFilter {
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb
  }

  .jwc-lportal .show_data .share_box span {
      display: block;
      margin-bottom: 10px
  }

  .jwc-lportal .show_data .share_box a:nth-child(2) {
      margin-left: 0
  }

  .jwc-lportal .show_data_partners ul {
      grid-template-columns: repeat(2, 1fr)
  }

  .jwc-lportal .show_data_partners ul li img {
      max-width: 100%;
      -o-object-fit: contain;
      object-fit: contain
  }
}

/*# sourceMappingURL=styles.css.map */